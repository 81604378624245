<template>
  <div></div>
</template>

<script>
import { vuexOidcProcessSilentSignInCallback } from "vuex-oidc";
import OidcSettings from "../configuration";
export default {
  mounted() {
    vuexOidcProcessSilentSignInCallback(OidcSettings);
  }
};
</script>
